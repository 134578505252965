<template>
  <div
    @keyup.esc="
      showPC = false;
      showPhone = false;
    "
  >
    <el-card>
      <h5>
        Question
      </h5>
      <hr />
      <div class="add-question" v-if="question">
        <div>
          <el-form ref="form" :model="question" label-width="100px">
            <el-form-item>
              <div slot="label">
                <div>
                  <el-popover placement="top" trigger="hover">
                    <div>
                      <el-alert
                        type="success"
                        :closable="false"
                        class="explanation"
                      >
                        <ul style="margin-bottom:0;line-height: 2;">
                          <li>
                            <kbd>Shift</kbd> +
                            <kbd>Enter</kbd>
                            {{ $t("AddQuestions.for line break") }}
                          </li>
                        </ul>
                      </el-alert>
                    </div>
                    <span
                      class="text-success"
                      style="cursor:pointer"
                      slot="reference"
                    >
                      <span class="text-danger">*</span>
                      {{ $t("AddQuestions.Question") }}
                      <i class="fas fa-question-circle"></i
                    ></span>
                  </el-popover>
                </div>
              </div>
              <div class="question-ck">
                <Ckeditor
                  :value="question.content || ''"
                  @input="value => $emit('setQuestion', { content: value })"
                />
              </div>
            </el-form-item>
            <el-form-item required>
              <span slot="label">
                {{ $t("AddQuestions.Type") }}
              </span>
              <el-radio-group v-model="question.type">
                <el-radio-button label="ap">
                  {{ $t("AddQuestions.Multiple Choice") }}
                </el-radio-button>
                <el-radio-button label="math">
                  {{ $t("AddQuestions.Grid-In") }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Difficulty">
              <el-radio-group v-model="question.difficulty">
                <el-radio-button :label="null" :value="null">
                  (Not Set)
                </el-radio-button>
                <el-radio-button
                  :key="index"
                  v-for="(difficulty, index) in DifficultyArr"
                  :label="difficulty.key"
                  :value="difficulty.key"
                >
                  {{ titleCase(difficulty.name) }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              :label="$t('AddQuestions.Choices')"
              required
              v-if="question.type === 'ap' || question.type === 'ap_macroeconomic'"
            >
              <div v-for="(option, index) in question.sat_options" :key="index">
                <div class="question-letter">
                  <div style="width: 45px;">
                    <el-checkbox
                      :label="option.letter"
                      :value="option.letter"
                      @change="selectedAnswers"
                      v-model="question.sat_answers[0].answers"
                    ></el-checkbox>
                  </div>
                  <!-- <el-input v-model="option.title" style="margin-left: 1rem;"></el-input> -->
                  <div style="flex: 1">
                    <Ckeditor v-model="option.title" />
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <div slot="label">
                <template v-if="question.type === 'default'">
                  <el-popover placement="top" trigger="hover">
                    <div>
                      <el-alert type="success" :closable="false">
                        <ul style="margin-bottom:0; line-height: 2">
                          <li>
                            {{ $t("AddQuestions.Check the right choice") }}
                          </li>
                          <li>
                            {{ $t("AddQuestions.If this question") }}
                          </li>
                        </ul>
                      </el-alert>
                    </div>
                    <span
                      class="text-success"
                      style="cursor:pointer"
                      slot="reference"
                    >
                      <span class="text-danger">*</span>
                      {{ $t("AddQuestions.Answer") }}
                      <i class="fas fa-question-circle"></i
                    ></span>
                  </el-popover>
                </template>
                <template v-else>
                  <el-popover
                    placement="top"
                    :title="$t('AddQuestions.Sample answer response')"
                    trigger="hover"
                  >
                    <div>
                      <el-alert type="success" class="mt-4" :closable="false">
                        <ul style="margin-bottom:0; line-height: 2">
                          <li
                            v-html="
                              $t('AddQuestions.All fields cannot be blank')
                            "
                          ></li>
                          <li v-html="$t('AddQuestions.right answer')"></li>
                          <!-- <li
                            v-for="(item, index) in answerExamples['default']"
                            :key="index"
                            v-html="item"
                          ></li> -->
                        </ul>
                        <!-- <h6 class="mt-3">Math</h6> -->
                        <ul style="margin-bottom:0; line-height: 2">
                          <li
                            v-for="(item, index) in answerExamples['math']"
                            :key="index"
                            v-html="item"
                          ></li>
                        </ul>
                      </el-alert>
                    </div>
                    <span
                      class="text-success"
                      style="cursor:pointer"
                      slot="reference"
                    >
                      {{ $t("AddQuestions.Answer") }}
                      <i class="fas fa-question-circle"></i
                    ></span>
                  </el-popover>
                </template>
              </div>
              <template v-if="question.type === 'default'">
                <div style="line-height: 40px;">
                  <b
                    class="text-success"
                    v-for="(answer, index) in question.sat_answers[0].answers"
                    :key="index"
                  >
                    {{ answer }}
                  </b>
                </div>
              </template>
              <template v-else>
                <div
                  class="d-flex mb-3"
                  v-for="(answer, index) in question.sat_answers"
                  :key="index"
                >
                  <el-input v-model="answer.answers[0]"></el-input>
                  <el-button
                    type="success"
                    class="ml-2"
                    @click="addAnswer"
                    v-if="index === 0"
                  >
                    <i class="fa fa-plus"></i>
                  </el-button>
                  <el-button
                    type="danger"
                    class="ml-2"
                    @click="removeAnswer(index)"
                    v-if="index > 0"
                  >
                    <i class="fas fa-minus"></i>
                  </el-button>
                </div>
              </template>
            </el-form-item>
            <el-form-item :label="$t('AddQuestions.Explanation')">
              <div class="explanation-content">
                <Ckeditor
                  :value="question.explanation || ''"
                  @input="value => $emit('setQuestion', { explanation: value })"
                />
              </div>
            </el-form-item>
            <el-form-item>
              <div slot="label">
                {{ $t("AddQuestions.Labels") }}
                <router-link :to="{ name: 'QuestionTags' }"
                  ><i class="fa fa-edit"></i
                ></router-link>
              </div>
              <el-select
                v-model="question.sat_question_tag_ids"
                multiple
                filterable
                style="width:100%"
              >
                <el-option
                  v-for="tag in tags"
                  :key="tag.id"
                  :label="`[${tag.type}] - ${tag.name}`"
                  :value="tag.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";
// import Ckeditor from "@/components/ckeditor/index.vue";
import Ckeditor from "@/components/ckeditor/ckeditor5.vue";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";

export default {
  // metaInfo() {
  //   return {
  //     title: "Add Question3 - " + this.CompanyName
  //   };
  // },

  components: {
    Ckeditor
  },

  mixins: [Common],

  props: ["question", "passage"],
  data() {
    return {
      tags: [],
      selectedTags: []
    };
  },
  computed: {
    DifficultyArr() {
      return [
        { name: "Beginner", key: "1" },
        { name: "Easy", key: "2" },
        { name: "Medium", key: "3" },
        { name: "Hard", key: "4" },
        { name: "Super Advanced", key: "5" }
      ];
    },
    helper() {
      return helper;
    },
    answerExamples() {
      return {
        default: [
          this.$t("AddQuestions.With 1 answer1"),
          this.$t("AddQuestions.With multiple answers1")
        ],
        math: [
          this.$t("AddQuestions.With 1 answer2"),
          this.$t("AddQuestions.With multiple answers2"),
          this.$t("AddQuestions.If the answer is a range"),
          this.$t("AddQuestions.If the answer is a range and contains")
        ]
      };
    }
  },
  watch: {
    question: {
      deep: true,
      handler() {
        this.setMath("ivy-question-content");
      }
    }
  },

  async mounted() {
    await this.getTags();
    if (this.question.sat_answers && this.question.sat_answers.length > 0) {
      this.question.sat_answers.forEach(answer => {
        if (answer.answers === null) {
          answer.answers = [""];
        }
      });
    }
    this.setMath("ivy-question-content");
    console.log(1);
  },

  methods: {
    selectedAnswers(answer) {
      console.log(answer);
      console.log(this.question.sat_answers[0].answers);
      let answersArr = [];
      this.question.sat_answers[0].answers.forEach(item => {
        if (item === "A" || item === "B" || item === "C" || item === "D") {
          answersArr.push(item);
        }
      });
      this.question.sat_answers[0].answers = answersArr;
    },
    setMath(ref) {
      this.$nextTick(() => {
        let tag = this.$refs[ref];
        if (this.$mathJaxSAT.isMathjaxConfig) {
          this.$mathJaxSAT.initMathjaxConfig();
        }
        if (tag) {
          this.$mathJaxSAT.MathQueue([tag]);
        }
      });
    },
    setType(value) {
      this.question.sat_answers = [
        {
          answers: []
        }
      ];
      this.$emit("setQuestion", { type: value });
    },
    replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    },
    async getTags() {
      const res = await SAT.getSATQuestionTags();
      this.tags = res.sat_question_tags;
    },
    addAnswer() {
      this.question.sat_answers.push({
        answers: [""]
      });
    },
    removeAnswer(index) {
      this.question.sat_answers.splice(index, 1);
    },
    handleClose() {
      this.$emit("closeDialog");
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    saveQuestion() {
      this.$emit("saveQuestion");
    }
  }
};
</script>

<style scoped>
.add-question {
  padding-bottom: 1rem;
}
::v-deep .el-dialog__body {
  height: 500px;
  overflow: auto;
}
::v-deep .el-alert__title {
  font-size: 16px;
}
::v-deep .el-alert .el-alert__description {
  font-size: 14px;
}
.question-letter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
::v-deep .question-letter .label {
  margin: 0;
}
::v-deep .el-input-group {
  margin-left: 2rem;
  width: auto;
  flex: 1;
}
::v-deep .sat-material-questionBox-MultipleChoice {
  margin-top: 0;
}
::v-deep .sat-material-container-MultipleChoice {
  height: auto;
  display: block;
}
::v-deep .MathJax .mrow {
  font-size: 1.2rem !important;
}
::v-deep .question-ck .ck-content {
  min-height: 40px;
  /* max-height: 200px; */
  overflow: auto;
}
::v-deep .explanation-content .ck-content {
  min-height: 200px;
  max-height: 300px;
}
::v-deep .smartEditor .ck-content {
  min-height: 200px;
  max-height: 600px;
}
::v-deep .add-passage-dialog .el-dialog__body {
  height: 800px;
  overflow: auto;
}

::v-deep .MathJax .mrow {
  font-size: 1.1rem !important;
}
</style>
