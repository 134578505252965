var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{ref:"form",attrs:{"label-width":"100px"}},[(!_vm.isEdit)?_c('el-form-item',{attrs:{"label":_vm.$t('AddPassage.Method')}},[_c('el-radio-group',{model:{value:(_vm.howToHandlePassages),callback:function ($$v) {_vm.howToHandlePassages=$$v},expression:"howToHandlePassages"}},[_c('el-radio-button',{attrs:{"label":"chooseExistPassage"}},[_vm._v(" "+_vm._s(_vm.$t('AddPassage.Choose from an existing passage'))+" ")]),_c('el-radio-button',{attrs:{"label":"createNewPassage"}},[_vm._v(" "+_vm._s(_vm.$t('AddPassage.Create a new passage'))+" ")])],1)],1):_vm._e(),(
        _vm.howToHandlePassages === 'createNewPassage' ||
          (_vm.questionId && _vm.questionId > 0)
      )?[(_vm.isNew === 0)?_c('el-form-item',{attrs:{"label":"Title"}},[_c('el-input',{attrs:{"placeholder":"Title","value":_vm.passage.title},on:{"input":title => _vm.$emit('setPassage', { title })}})],1):_vm._e(),(_vm.isNew === 0 && _vm.passage.introduction)?_c('el-form-item',{attrs:{"label":"Intro"}},[_c('Ckeditor',{attrs:{"value":_vm.passage.introduction.intro},on:{"input":intro =>
              _vm.$emit('setPassage', {
                introduction: {
                  intro: intro,
                  below_content: _vm.passage.introduction.below_content
                }
              })}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"Passage"}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('div',[_vm._v(" Passage ")])]),_c('div',[(!_vm.isNew)?_c('h5',[_vm._v("Passage I")]):_vm._e(),_c('Ckeditor',{attrs:{"enableQuestionNumStyle":!_vm.isNew,"value":_vm.passage.content},on:{"input":content => _vm.$emit('setPassage', { content: content })}})],1),(_vm.showPassage2)?_c('div',{staticClass:"mt-3"},[_c('h5',[_vm._v("Passage II")]),_c('Ckeditor',{attrs:{"enableQuestionNumStyle":true,"value":_vm.passage.introduction.below_content},on:{"input":below_content =>
                _vm.$emit('setPassage', {
                  introduction: {
                    intro: _vm.passage.introduction.intro,
                    below_content: below_content
                  }
                })}})],1):_c('div',{staticClass:"mt-3"},[(!_vm.isNew)?_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){_vm.showPassage2 = true}}},[_vm._v(" Add Passage II ")]):_vm._e()],1)])]:_vm._e()],2),(
      _vm.howToHandlePassages === 'chooseExistPassage' &&
        !(_vm.questionId && _vm.questionId > 0)
    )?[_c('el-input',{attrs:{"placeholder":_vm.$t('AddPassage.Type keyword here')},model:{value:(_vm.keyWord),callback:function ($$v) {_vm.keyWord=$$v},expression:"keyWord"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.searchPassages},slot:"append"},[_vm._v(" Search ")])],1),_c('hr'),_c('el-table',{attrs:{"data":_vm.passages,"border":""}},[_c('el-table-column',{attrs:{"prop":"content","label":"Passages"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('b',[_vm._v(_vm._s(scope.row.title))]),_c('div',{domProps:{"innerHTML":_vm._s(scope.row.content)}}),_c('div',{domProps:{"innerHTML":_vm._s(scope.row.introduction.below_content)}})]}}],null,false,3317419658)}),_c('el-table-column',{attrs:{"label":"Action","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.questionId !== scope.row.id)?_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":function($event){return _vm.$emit('selectPassage', scope.row)}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Select ")]):_vm._e()]}}],null,false,3814196213)})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }